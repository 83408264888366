define("discourse/plugins/discourse-group-addons/discourse/helpers/is-avatar-flair", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isAvatarFlair;
  (0, _helpers.registerRawHelper)('isAvatarFlair', isAvatarFlair);
  function isAvatarFlair(group, flairGroupId) {
    return group.id == flairGroupId;
  }
});