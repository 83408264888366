define("discourse/plugins/discourse-group-addons/discourse/initializers/group-addons", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/helpers"], function (_exports, _pluginApi, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'group-addons',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.8.22', api => {
        api.includePostAttributes("available_flairs");
        api.decorateWidget('post-meta-data:after', helper => {
          return helper.widget.attach('group-flairs', helper.attrs);
        });
      });
    }
  };
});