define("discourse/plugins/discourse-group-addons/discourse/widgets/group-flairs", ["exports", "discourse/widgets/widget", "discourse-common/lib/icon-library", "discourse/lib/utilities", "virtual-dom"], function (_exports, _widget, _iconLibrary, _utilities, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)('group-flairs', {
    tagName: 'div.meta-data-group-flairs',
    buildKey: () => 'group-flairs',
    html(attrs) {
      let content = [];
      let avatarFlair = attrs.flair_group_id;
      let flairs = attrs.available_flairs;
      for (let flair of flairs) {
        if (flair.id != avatarFlair) {
          let flairIcon = [];
          let classes = '';
          let style = '';
          if (flair.flair_url && !flair.flair_url.includes('/')) {
            let icon = (0, _iconLibrary.convertIconClass)(flair.flair_url);
            flairIcon.push((0, _iconLibrary.iconNode)(icon));
          } else if (flair.flair_url) {
            style += "background-image: url(" + (0, _utilities.escapeExpression)(flair.flair_url) + "); ";
          }
          if (flair.flair_bg_color) {
            classes += '.rounded';
            style += "background-color: #" + (0, _utilities.escapeExpression)(flair.flair_bg_color) + "; ";
          }
          if (flair.flair_color) {
            style += "color: #" + (0, _utilities.escapeExpression)(flair.flair_color) + "; ";
          }
          let props = {
            attributes: {
              'style': style
            },
            title: flair.name
          };
          content.push((0, _virtualDom.h)('div.group' + classes, props, flairIcon));
        }
      }
      return (0, _virtualDom.h)('div.extended-groups', content);
    }
  });
});