define("discourse/plugins/discourse-group-addons/discourse/templates/connectors/user-post-names/user-profile-names-group-flairs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="extended-groups">
    {{#each @outletArgs.model.groups as |group|}}
      {{#unless group.automatic}}
        {{#unless (isAvatarFlair group @outletArgs.model.flair_group_id)}}
          <span class="group">
            <AvatarFlair
              @flairName={{group.name}}
              @flairUrl={{group.flair_url}}
              @flairBgColor={{group.flair_bg_color}}
              @flairColor={{group.flair_color}}
            />
          </span>
        {{/unless}}
      {{/unless}}
    {{/each}}
  </div>
  
  */
  {
    "id": "KGYYsmnm",
    "block": "[[[10,0],[14,0,\"extended-groups\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"model\",\"groups\"]]],null]],null],null,[[[41,[51,[30,2,[\"automatic\"]]],[[[41,[51,[28,[37,3],[[30,2],[30,1,[\"model\",\"flair_group_id\"]]],null]],[[[1,\"        \"],[10,1],[14,0,\"group\"],[12],[1,\"\\n          \"],[8,[39,4],null,[[\"@flairName\",\"@flairUrl\",\"@flairBgColor\",\"@flairColor\"],[[30,2,[\"name\"]],[30,2,[\"flair_url\"]],[30,2,[\"flair_bg_color\"]],[30,2,[\"flair_color\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[2]],null],[13],[1,\"\\n\"]],[\"@outletArgs\",\"group\"],false,[\"each\",\"-track-array\",\"unless\",\"isAvatarFlair\",\"avatar-flair\"]]",
    "moduleName": "discourse/plugins/discourse-group-addons/discourse/templates/connectors/user-post-names/user-profile-names-group-flairs.hbs",
    "isStrictMode": false
  });
});